import React from 'react';
import parse from 'html-react-parser';
import { HashLink } from 'react-router-hash-link';

export default function HeroStyle5({
  title,
  subTitle,
  bgUrl,
  imgUrl,
  btnText,
  btnUrl,
}) {
  return (
      <section className="cs_hero cs_style_2 cs_type_1" style={{ backgroundImage: `url(${bgUrl})` }}>
        <div className="container">
          <div className="cs_hero_text">
            <h1 className="cs_banner_title cs_fs_72 cs_black_color">
              {parse(title)}
            </h1>
            <div className="cs_hero_text_in">
              <div className="cs_hero_info_col">
                <p className="cs_banner_subtitle cs_fs_20 mb-0 cs_heading_color">{subTitle}</p>
                <br></br>
                {
                  btnUrl ?
                    <HashLink smooth to={btnUrl} className="cs_btn cs_style_1">
                      <span>{btnText}</span>
                      <i>
                        <img src="/images/icons/arrow_white.svg" alt="Icon"/>
                      </i>
                    </HashLink> : null
                }
              </div>
            </div>
          </div>
        </div>
        <div className="cs_hero_img cs_img_border_radius" style={{"transform": "scale(0.8)"}}>
          <img src={imgUrl} alt="Hero"/>
        </div>
      </section>
  );
}
