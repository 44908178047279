import React, { useState } from "react";
import Select from "react-select";
import SpinAnimation from "../Widget/SpinAnimation";
import {postData} from "../../api/postData";

export default function CourseRegistrationForm({ selectedCourse, courses }) {
  const courseOptions = courses.map((course) => ({ value: course.name, label: course.name }));
  const initialCourse = selectedCourse && courseOptions.find(option => option.value === selectedCourse) ? selectedCourse : "";
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    surname: "",
    other_names: "",
    email: "",
    phone: "",
    course_name: initialCourse
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSelectChange = (selectedOption) => {
    setFormData({ ...formData, course_name: selectedOption ? selectedOption.value : "" });
  };

  async function handleSubmit (e) {
    e.preventDefault();
    try {
      setLoading(true);
      if (e.target.checkValidity()) {
        await postData('school-enquiry', '', formData);
        alert('Your enquiry has been received. We will get in touch soon for the next steps.');
        setFormData(
          {
            surname: "",
            other_names: "",
            email: "",
            phone: "",
            course_name: initialCourse
          }
        );
      }
    } catch (e) {
      console.log(e);
      alert("An error occurred. Please try again later or contact us via phone.");
    } finally {
      setLoading(false);
    }
  }

  return (
    <form id="enroll" onSubmit={handleSubmit} className="col-md-6 card mx-auto cs_radius_30 bg-light">
      <div className="card-body m-4">
        <h3 className="text-md-center card-title mb-4">Apply for a Course</h3>
        <div className="row">
          {/* Surname */}
          <div className="col-md-6 mb-3">
            <label htmlFor="surname">Surname</label>
            <input required type="text" id="surname" name="surname" value={formData.surname} onChange={handleChange} className="form-control" />
          </div>

          {/* Other Names */}
          <div className="col-md-6 mb-3">
            <label htmlFor="otherNames">Other Names</label>
            <input required type="text" id="otherNames" name="other_names" value={formData.other_names} onChange={handleChange}  className="form-control" />
          </div>

          {/* Email */}
          <div className="col-md-6 mb-3">
            <label htmlFor="email">Email</label>
            <input required type="email" id="email" name="email" value={formData.email} onChange={handleChange} className="form-control" />
          </div>

          {/* Phone Number */}
          <div className="col-md-6 mb-3">
            <label htmlFor="phoneNumber">Phone Number</label>
            <input  required type="tel" id="phoneNumber" name="phone" value={formData.phone} onChange={handleChange} className="form-control" />
          </div>

          {/* Course of Choice */}
          <div className="col-md-12 mb-3">
            <label htmlFor="courseChoice">Course of Choice</label>
            <Select
              id="courseChoice"
              required
              value={courseOptions.find(option => option.value === formData.course_name) || null}
              onChange={handleSelectChange}
              options={courseOptions}
              className="form-control"
              isClearable
            />
          </div>
        </div>

        {/* Submit Button */}
        {
          loading ? <SpinAnimation /> :
            <button type="submit" className="btn btn-primary">Submit</button>
        }


      </div>

    </form>
  );
}
