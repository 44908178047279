/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */

import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import {DatePicker} from 'antd';
import 'react-datepicker/dist/react-datepicker.css';
import dayjs from 'dayjs';
import { fetchData, fetchList } from '../../api/fetchData';
import SpinAnimation from '../Widget/SpinAnimation';
import { postData } from '../../api/postData';
import { useNavigate } from 'react-router-dom';

export default function AppointmentForm() {
  const navigate = useNavigate();
  const [selectedGender, setSelectedGender] = useState('');
  const [date, setDate] = useState('');
  const [loading, setLoading] = useState(false);
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [dateStr, setDateStr] = useState('');
  const [surname, setSurname] = useState('');
  const [otherNames, setOtherNames] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [isClicked, setIsClicked] = useState(false);
  const [serviceCategories, setServiceCategories] = useState([]);
  const [groupedServices, setGroupedServices] = useState({});
  const [selectedServiceCategory, setSelectedServiceCategory] = useState('');
  const [services, setServices] = useState([]);
  const [selectedService, setSelectedService] = useState('');

  const genderOptions = [
    { value: 'Male', label: 'Male' },
    { value: 'Female', label: 'Female' }
  ];

  const onDateChange = (dt) => {
    setIsClicked(true);
    setDate(dt);
    setDateStr(dayjs(dt).format('YYYY-MM-DD'));
  };

  async function onSubmit(e) {
    e.preventDefault();
    console.log("about to submit")
    try {
      setLoading(true);
      if (e.target.checkValidity()) {
        const data = {
          surname,
          other_names: otherNames,
          email,
          phone_number: mobile,
          gender: selectedGender,
          service_id: selectedService,
          appointment_start_date: dayjs(dateStr + ' ' + startTime).toISOString(),
          appointment_end_date: dayjs(dateStr + ' ' + endTime).toISOString()
        };
        await postData('create-appointment', '', data);
        alert('Appointment created successfully. Check your email for the next steps');
        navigate('/');
      }
    } catch (e) {
      alert(e.message);
    } finally {
      setLoading(false);
    }
  }

  // Render services
  useEffect(() => {
    async function loadCategories() {
      try {
        const apiData = await fetchList('service-category', '', false);
        setServiceCategories(apiData.data);
      } catch (e) {
        console.log(`Unable to fetch categories: ${e.message}`);
      }
    }
    async function loadServices() {
      try {
        const apiData = await fetchList('service', '', false);
        const groupedServices = apiData.data.reduce((acc, service) => {
          const category = service.service_category;
          // If the category key doesn't exist, initialize it as an empty array
          if (!acc[category]) {
            acc[category] = [];
          }
          // Add the current service to the category array
          acc[category].push(service);
          return acc;
        }, {});
        setGroupedServices(groupedServices);
      } catch (e) {
        console.log(`Unable to fetch services: ${e.message}`);
      }
    }
    Promise.all([loadCategories(), loadServices()]);
  }, []);

  useEffect(() => {
    if (selectedServiceCategory) {
      const lst = groupedServices[selectedServiceCategory];
      if (lst) {
        setServices(lst);
      }
    }
  }, [selectedServiceCategory, groupedServices]);

  // Convert service categories to options format for react-select
  const categoryOptions = serviceCategories.map((category) => ({
    value: category.name,
    label: category.name
  }));

  // Convert services to options format for react-select
  const serviceOptions = services.map((service) => ({
    value: service.id,
    label: `${service.name} (KES. ${Number(service.price).toLocaleString()})`
  }));

  return (
    <form onSubmit={onSubmit} className="card mx-auto cs_radius_30 bg-light col-md-10">
      <div className="card-body m-4">
        {/*<h3 className="text-md-center card-title mb-4">Book an Appointment</h3>*/}

        <div className="row">
          {/* Personal Details Section */}
          <div className="col-12">
            <h5>Personal Details</h5>
          </div>

          {/* Surname */}
          <div className="col-md-6 mb-3">
            <label htmlFor="surname">Surname</label>
            <input
              id="surname"
              type="text"
              value={surname}
              onChange={(e) => setSurname(e.target.value)}
              required
              className="form-control"
            />
          </div>

          {/* Other Names */}
          <div className="col-md-6 mb-3">
            <label htmlFor="otherNames">Other Names</label>
            <input
              id="otherNames"
              type="text"
              value={otherNames}
              onChange={(e) => setOtherNames(e.target.value)}
              className="form-control"
              required
            />
          </div>

          {/* Gender - using react-select */}
          <div className="col-md-6 mb-3">
            <label htmlFor="gender">Gender</label>
            <Select
              id="gender"
              value={genderOptions.find(option => option.value === selectedGender) || null}
              onChange={(selectedOption) => setSelectedGender(selectedOption ? selectedOption.value : '')}
              options={genderOptions}
              className="basic-select"
              classNamePrefix="select"
              placeholder="Select gender"
              required
            />
          </div>

          {/* Phone Number */}
          <div className="col-md-6 mb-3">
            <label htmlFor="phoneNumber">Phone Number</label>
            <input
              id="phoneNumber"
              type="text"
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
              className="form-control"
              required
            />
          </div>

          {/* Appointment Details Section */}
          <div className="col-12 mt-3">
            <h5>Appointment Details</h5>
          </div>

          {/* Email */}
          <div className="col-md-6 mb-3">
            <label htmlFor="email">Email</label>
            <input
              id="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="form-control"
            />
          </div>

          {/* Service Category - using react-select */}
          <div className="col-md-6 mb-3">
            <label htmlFor="serviceCategory">Service Category</label>
            <Select
              id="serviceCategory"
              value={categoryOptions.find(option => option.value === selectedServiceCategory) || null}
              onChange={(selectedOption) => setSelectedServiceCategory(selectedOption ? selectedOption.value : '')}
              options={categoryOptions}
              className="basic-select"
              classNamePrefix="select"
              placeholder="Select a category"
              required
            />
          </div>

          {/* Service - only shown when category is selected */}
          {selectedServiceCategory && (
            <div className="col-md-6 mb-3">
              <label htmlFor="service">Service</label>
              <Select
                id="service"
                value={serviceOptions.find(option => option.value === selectedService) || null}
                onChange={(selectedOption) => setSelectedService(selectedOption ? selectedOption.value : '')}
                options={serviceOptions}
                className="basic-select"
                classNamePrefix="select"
                placeholder="Select a service"
                required
              />
            </div>
          )}

          {/* Appointment Date */}
          <div className="col-md-4 mb-3">
            <label htmlFor="appointmentDate">Appointment Date</label>
            <DatePicker
              id="appointmentDate"
              className="form-control"
              selected={date}
              onChange={onDateChange}
              required
              placeholderText="Select date"
            />
          </div>

          {/* Start Time */}
          <div className="col-md-4 mb-3">
            <label htmlFor="startTime">Start Time</label>
            <input
              id="startTime"
              type="time"
              className="form-control"
              value={startTime}
              onChange={(e) => setStartTime(e.target.value)}
              required
            />
          </div>

          {/* End Time */}
          <div className="col-md-4 mb-3">
            <label htmlFor="endTime">End Time</label>
            <input
              id="endTime"
              type="time"
              className="form-control"
              value={endTime}
              onChange={(e) => setEndTime(e.target.value)}
              required
            />
          </div>
        </div>

        {/* Submit Button */}
        <div className="text-center mt-3">
          {loading ?
            <SpinAnimation /> :
            <button type="submit" className="btn btn-primary" >
              Submit
            </button>
          }
        </div>
      </div>
    </form>
  );
}