import React from 'react';
import Banner from '../Section/BannerSection';
import Section from '../Section';
import FeaturesSection from '../Section/FeaturesSection';
import AppointmentSection from '../Section/AppointmentSection';
import AwardSection from '../Section/AwardSection';
import { pageTitle } from '../../helpers/PageTitle';
import HeroStyle5 from "../Hero/HeroStyle5";
import AboutSectionStyle5 from "../Section/AboutSection/AboutSectionStyle5";
import AboutSectionStyle4 from "../Section/AboutSection/AboutSectionStyle4";
import GallerySectionStyle2 from "../Section/GallerySection/GallerySectionStyle2";
import Spacing from "../Spacing";

const aboutText = "At E-tiba nursing home, we understand that each resident is unique. Our experienced team provides customized care tailored to individual needs, ensuring peace of mind for families and a nurturing environment for all.";

const galleryText = "Selecting a skilled nursing facility can be challenging but—as they say—pictures can be worth a thousand words. We invite you to get to know us a little better by taking a virtual photo tour.";

const galleryData = [
  { imgUrl: '/images/nursing-home/facilities/facilities-1.jpg' },
  { imgUrl: '/images/nursing-home/facilities/facilities-3.jpg' },
  { imgUrl: '/images/nursing-home/facilities/facilities-4.jpg' },
];

const featureListData = [
  {
    iconSrc: '/images/home_1/compassion.svg',
    title: 'Compassion',
    subTitle:
      'We understand that seeking medical care can be a stressful and emotional experience, and we strive to create a welcoming and supportive environment that puts our patients at ease.',
  },
  {
    iconSrc: '/images/home_1/excellence.svg',
    title: 'Excellence',
    subTitle:
      'We are committed to providing excellent medical care and services to our patients. We believe in continuously improving our skills, knowledge, and resources to ensure that we deliver the highest quality care possible.',
  },
  {
    iconSrc: '/images/home_1/integrity.svg',
    title: 'Integrity',
    subTitle: `We believe in practicing medicine with integrity and honesty. We are transparent in our communication and decision-making processes, and we always put our patient's interests first & provide best solution.`,
  },
  {
    iconSrc: '/images/home_1/respect.svg',
    title: 'Experienced Staff',
    subTitle:
      'Well trained and highly skilled health professionals',
  },
  {
    iconSrc: '/images/home_1/teamwork.svg',
    title: 'Convenience and Flexibility',
    subTitle:
      'We offer flexible scheduling and convenient care options to fit your lifestyle',
  },
];

const awardData = [
  {
    title: 'Assisted Living',
    subTitle:
      'Trust us to provide a safe and comfortable home away from home. This includes helping a patient with chores, meal preparation, laundry, and other housekeeping items to enable them to stay safe at home.',
    iconUrl: 'images/services/assisted-living.png',
  },
  {
    title: 'Therapy',
    subTitle:
      'Looking for professional therapy services for yourself or a loved one? We offer a variety of therapeutic services including physiotherapy, speech and occupational therapies to help you improve and recover from an illness',
    iconUrl: 'images/services/therapy.png',
  },
  {
    title: 'Mental HealthCare',
    subTitle:
        'Our mental healthcare services provide compassionate and evidence-based treatment for individuals struggling with a variety of mental health concerns. Find the support you need to improve your mental well-being today.',
    iconUrl: 'images/services/mental-health.png',
  },
  {
    title: 'End-of-life Care',
    subTitle:
        'We offer physical, mental, and emotional support to your loved ones, ensuring they receive comfort and peace in their remaining days. Our dedicated team is here to support both residents and families through this challenging time with compassion and professionalism',
    iconUrl: 'images/services/nursing-services.png',
  },
];

export default function NursingHome() {
  pageTitle('Nursing Home');
  return (
    <>
      <HeroStyle5
          title="E-Tiba Nursing Home"
          subTitle="At our nursing home, we providing a safe and nurturing environment for your loved ones, where every day feels like home. Explore our personalized care options and discover a place where dignity, respect, and community thrive."
          imgUrl="/images/home_1/hero_img_cropped.png"
          btnText="Start your journey with us"
          btnUrl="/appointments"
          bgUrl="/images/home_1/cta_bg.svg"
      />

      {/* Start About Section */}
      <Section
        topMd={185}
        topLg={140}
        topXl={100}
        bottomMd={185}
        bottomLg={140}
        bottomXl={100}
      >
        <AboutSectionStyle5
          imgUrl="/images/nursing-home/facilities/facilities-hero.jpeg"
          title="About Us"
          subTitle={aboutText}
        />
      </Section>
      {/* End About Section */}

      {/* Start Services Section */}
      <Section
        bottomMd={185}
        bottomLg={140}
        bottomXl={100}
      >
        <AwardSection sectionTitle="Our Services" data={awardData} />
      </Section>
      {/* End Services Section */}

      {/* Start Why-choose-us Section */}
      <Section
        bottomMd={185}
        bottomLg={140}
        bottomXl={100}
      >
        <FeaturesSection sectionTitle="Why Choose Us" data={featureListData} />
      </Section>
      {/* End Why-choose-us Section */}

      {/* Start Gallery Section */}
      <Section
        bottomMd={185}
        bottomLg={140}
        bottomXl={100}
      >
        {/*<SectionHeading title={"Our Facilities"} center/>*/}
        <AboutSectionStyle4
          imgUrl="images/nursing-home/facilities/facilities-2.jpg"
          title="Our Facilities"
          subTitle={galleryText}
        />
        <Spacing md="30" lg="50" />
        <GallerySectionStyle2
          data={galleryData}
        />
      </Section>
      {/* End Gallery Section */}

      {/* Start Banner Section */}
      <Section
        bottomMd={185}
        bottomLg={140}
        bottomXl={100}
      >
        <Banner
          bgUrl="images/home_1/cta_bg.svg"
          imgUrl="images/home_1/cta_img.png"
          title="Make the Best Choice for Their Future!"
          subTitle="Admit your loved one to a community of care, comfort, and support. Start the admission process today and give them the compassionate care they deserve!"
        />
      </Section>
      {/* End Banner Section */}

      {/* End Blog Section */}
      {/* Start Appointment Section */}
      <Section
        id="appointment"
        bottomMd={185}
        bottomLg={140}
        bottomXl={100}
      >
        <AppointmentSection
          sectionTitle="Appointment"
          sectionTitleUp="BOOK AN"
          imgUrl="/images/home_1/appointment.avif"
        />
      </Section>
      {/* End Appointment Section */}
    </>
  );
}
