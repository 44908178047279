import {pageTitle} from "../../helpers/PageTitle";
import HeroStyle5 from "../Hero/HeroStyle5";
import React from "react";
import Section from "../Section";
import SectionHeading from "../SectionHeading";
import Banner from "../Section/BannerSection";
import CourseRegistrationForm from "../CourseRegistration/CourseRegistrationForm";
import Spacing from "../Spacing";
import AboutSectionStyle4 from "../Section/AboutSection/AboutSectionStyle4";
import GallerySectionStyle2 from "../Section/GallerySection/GallerySectionStyle2";

const heroText = "At E-tiba Training College, we empower individuals with the skills and knowledge needed for" +
  " a successful career in home care. Join our community and discover the path to compassionate" +
  " caregiving by applying for one of our expert-led courses!";

const galleryText = "A photo is worth a thousand words! Take a peek at our facilities and training activities."

const galleryData = [
  { imgUrl: '/images/school/gallery/gallery-2.jpg' },
  { imgUrl: '/images/school/gallery/gallery-3.jpg' },
  { imgUrl: '/images/school/gallery/gallery-4.jpg',},
  { imgUrl: '/images/school/gallery/gallery-5.jpg' },
  { imgUrl: '/images/school/gallery/gallery-6.jpg' },
  { imgUrl: '/images/school/gallery/gallery-7.jpg' },
];

const mainCourses = [
  {
    name: "Certified Nursing Assistant (CNA)",
    duration: "4 months"
  },
  {
    name: "Health Service Support (HSS)",
    duration: "6 months"
  },
  {
    name: "Perioperative Theatre Technology (for students without nursing experience)",
    duration: "1 year"
  },
  {
    name: "Perioperative Theatre Technology (for students with nursing experience)",
    duration: "6 months"
  },
  {
    name: "Dental/Community Oral Assistant",
    duration: "6 months"
  },
  {
    name: "Certificate in Community Health",
    duration: "1 year"
  },
];

mainCourses.sort((a, b) => a.name.localeCompare(b.name));

const shortCourses = [
  {
    name: "Emergency Courses: First Aid, BLS, ACLS, ATLS, PALS",
    duration: "3 months"
  }, {
    name: "Mental Health for Caregivers",
    duration: "3 months"
  }, {
    name: "NCLEX Preparation",
    duration: "3 months"
  }, {
    name: "Italian Language",
    duration: "3 months"
  }, {
    name: "Computer Packages/ICDL",
    duration: "3 months"
  },
];

shortCourses.sort((a, b) => a.name.localeCompare(b.name))

const allCourses = [...mainCourses, ...shortCourses];
allCourses.sort((a, b) => a.name.localeCompare(b.name));

export default function School() {
  pageTitle("College");
  return (
    <>
      <HeroStyle5
        title="E-tiba Training College"
        subTitle={heroText}
        imgUrl="/images/school/gallery/school-hero.jpeg"
        bgUrl="/images/home_1/cta_bg.svg"
        btnText="Apply for a course"
        btnUrl="#enroll"
      />

      {/* List of courses section */}
      <Section
        topMd={185}
        topLg={140}
        topXl={100}
        bottomMd={185}
        bottomLg={140}
        bottomXl={100}
      >
        <SectionHeading title={"Our Courses"} center/>
        <div className="p-4">
          <div className="col-md-10">
            {/* Main Courses Table */}
            <h3 className="text-md-center cs_section_subtitle cs_semibold cs_fs_32">Main Courses</h3>
            <table className="border-collapse border">
              <thead>
              <tr className="">
                <th className="border px-4 py-2 text-muted">Course Name</th>
                <th className="border px-4 py-2 text-muted">Duration</th>
              </tr>
              </thead>
              <tbody>
              {mainCourses.map((course, index) => (
                <tr key={index} className={index % 2 !== 0 ? "bg-white" : "bg-body-secondary"}>
                  <td className="border px-4 py-2 text-muted">{course.name}</td>
                  <td className="border px-4 py-2 text-muted">{course.duration}</td>
                </tr>
              ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="p-4 row table-responsive{-sm|-md|-lg|-xl}">
          <div className="col-md-10">
            {/* Short Courses Table */}
            <h3 className="text-md-center cs_section_subtitle  cs_semibold  cs_fs_32">Short Courses</h3>
            <table className="w-full border-collapse border">
              <thead>
              <tr className="">
                <th className="border px-4 py-2 text-muted">Course Name</th>
                {/*<th className="border px-4 py-2 text-muted">Duration</th>*/}
              </tr>
              </thead>
              <tbody>
              {shortCourses.map((course, index) => (
                <tr key={index} className={index % 2 !== 0 ? "bg-white" : "bg-body-secondary"}>
                  <td className="border px-4 py-2 text-muted">{course.name}</td>
                  {/*<td className="border px-4 py-2 text-muted">{course.duration}</td>*/}
                </tr>
              ))}
              </tbody>
            </table>
          </div>
        </div>
      </Section>

      {/* Gallery Section */}
      <Section
        bottomMd={10}
        bottomLg={10}
        bottomXl={10}
      >
        {/*<SectionHeading title={"Our Facilities"} center/>*/}
        <AboutSectionStyle4
          imgUrl="/images/school/gallery/gallery-1.jpg"
          title="Our Facilities and Activities"
          subTitle={galleryText}
        />
        <Spacing md="30" lg="50" />
        <GallerySectionStyle2
          data={galleryData}
        />
      </Section>

      {/* Banner CTA section */}
      <Section
        bottomMd={185}
        bottomLg={140}
        bottomXl={100}
      >
        <Banner
          bgUrl="images/home_1/cta_bg.svg"
          // imgUrl="/images/school/graduation2.webp"
          title="Register today and get started with your learning journey!"
          subTitle="Fill the form below and we'll get in touch on the next steps."
        />
      </Section>
      <Section
        bottomMd={185}
        bottomLg={140}
        bottomXl={100}
      >
        <CourseRegistrationForm courses={allCourses} />
      </Section>

    </>
  );
}