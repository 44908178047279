import React from 'react';
import Spacing from '../../Spacing';

const data = [
  { imgUrl: 'images/partners/TVET.jpg', imgAlt: 'TVET logo' },
  { imgUrl: 'images/partners/NITA.png', imgAlt: 'NITA logo' },
  { imgUrl: 'images/partners/muranga.webp', imgAlt: 'Muranga logo' },
  { imgUrl: 'images/partners/e4impact.jpg', imgAlt: 'E4impact logo' },
  { imgUrl: 'images/partners/kutrrh.png', imgAlt: 'KUTTRH logo' },
  { imgUrl: 'images/partners/awe.jpeg', imgAlt: 'AWE logo' },
  { imgUrl: 'images/partners/ets-logo.png', imgAlt: 'ETS logo' },
  { imgUrl: 'images/partners/fdr.png', imgAlt: 'FDR logo' },
  { imgUrl: 'images/partners/ninacare.jpeg', imgAlt: 'Nina care logo' },
  { imgUrl: 'images/partners/afyabook.jpeg', imgAlt: 'Afya book logo' },
];

export default function BrandsSection({ sectionTitle }) {
  return (
    <div className="container overflow-x-auto">
      {sectionTitle && (
        <>
          <h2 className="cs_fs_40 text-center mb-0 cs_semibold">
            {sectionTitle}
          </h2>
          <Spacing md="60" />
        </>
      )}

      <div className="cs_brands cs_style_1">
        {data?.map((item, index) => (
          <div className="cs_brand cs_center" key={index}>
            <img src={item.imgUrl} alt={item.imgAlt} />
          </div>
        ))}
      </div>
    </div>
  );
}
