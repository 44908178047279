import React from 'react';
import ContactInfoWidget from '../Widget/ContactInfoWidget';
import MenuWidget from '../Widget/MenuWidget';
import SocialWidget from '../Widget/SocialWidget';
// import Newsletter from "../Widget/Newsletter";
const menuDataOne = [
  { title: 'Home', href: '/' },
  { title: 'About Us', href: '/about' },
  // { title: 'Our Staff', href: '/doctors' },
  { title: 'Contact Us', href: '/contact' },
  { title: 'Book an appointment', href: '/appointments' },
];
const menuDataTwo = [
  { title: 'Terms and Conditions of Care', href: '/' },
  { title: 'Home Based Care Policy', href: '/' },
  { title: 'Privacy Policy', href: '/' },
  { title: 'Terms of Use', href: '/' },
];
const currentYear = new Date().getFullYear();

export default function Footer() {
  return (
    <footer className="cs_footer cs_style_1 cs_heading_color">
      <div
        className="cs_footer_logo_wrap"
        style={{ backgroundImage: 'url(/images/footer_bg_1.svg)' }}
      >
        <div
          className="cs_footer_brand"
          style={{ backgroundImage: 'url(/images/footer_logo_bg.svg)' }}
        >
          <img
            src="/images/logo.webp"
            alt="Logo Icon"
            className="cs_footer_brand_icon"
          />
        </div>
      </div>
      <div className="cs_footer_main">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="cs_footer_item">
                <h4>Contact Us</h4>
                <ContactInfoWidget />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="cs_footer_item">
                <h4>Menu</h4>
                <MenuWidget data={menuDataOne} />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="cs_footer_item">
                <h4>Our Policies</h4>
                <MenuWidget data={menuDataTwo}/>
              </div>
            </div>
            {/*<div className="col-lg-4">*/}
            {/*  <div className="cs_footer_item">*/}
            {/*    <Newsletter*/}
            {/*      title="Subscribe to Our Newsletter"*/}
            {/*    />*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>
        </div>
      </div>
      <div className="cs_footer_bottom cs_accent_bg">
        <div className="container">
          <div className="cs_footer_bottom_in">
            <SocialWidget />
            <div className="cs_copyright">
              Copyright © { currentYear } E-tiba. All rights reserved.
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
