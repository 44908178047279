import React, { useState } from 'react';
import {postData} from "../../api/postData";
import SpinAnimation from "../Widget/SpinAnimation";

export default function ContactForm() {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  async function handleSubmit (e) {
    e.preventDefault();
    try {
      setLoading(true);
      if (e.target.checkValidity()) {
        await postData('contact-us', '', formData);
        alert('Your enquiry has been received. We will get in touch soon!');
        setFormData(
          {
            name: '',
            email: '',
            subject: '',
            message: ''
          }
        );
      }
    } catch (e) {
      console.log(e);
      alert("An error occurred. Please try again later or contact us via phone.");
    } finally {
      setLoading(false);
    }
  }

  return (
    <form onSubmit={handleSubmit} className="col-md-8 card mx-auto cs_radius_30 bg-light">
      <div className="card-body m-4">
        <h3 className="text-md-center card-title mb-4">Contact Us</h3>
        <div className="row">
          {/* Name */}
          <div className="col-md-6 mb-3">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="form-control"
              required
            />
          </div>

          {/* Email */}
          <div className="col-md-6 mb-3">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="form-control"
              required
            />
          </div>

          {/* Subject */}
          <div className="col-12 mb-3">
            <label htmlFor="subject">Subject</label>
            <input
              type="text"
              id="subject"
              name="subject"
              value={formData.subject}
              onChange={handleChange}
              className="form-control"
              required
            />
          </div>

          {/* Message */}
          <div className="col-12 mb-3">
            <label htmlFor="message">Message</label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              className="form-control"
              rows={5}
              required
            />
          </div>
        </div>

        {/* Submit Button */}
        <div className="text-center mt-3">
          {
            loading ? <SpinAnimation /> :
              <button type="submit" className="btn btn-primary">Submit</button>
          }
        </div>
      </div>
    </form>
  );
}