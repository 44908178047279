import React from 'react';
import AppointmentForm from '../../AppointmentForm';

export default function AppointmentWithContactInfoSection() {
  return (
    <div className="container">
      <div className="row">
        <h2 className="cs_fs_40 cs_medium mb-0 text-lg-center">Book an Appointment</h2>
        <div className="col-sm-12">
          <div className="cs_height_42 cs_height_xl_25"/>
          <AppointmentForm/>
        </div>
      </div>
    </div>
  );
}
